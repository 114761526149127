import * as React from "react";
import {
  internalGet,
  internalPost,
  internalPut,
} from "@/common/http/httpServices";
import { useRouter } from "next/router";

const NAME = "Auth";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const AuthContext = React.createContext<any | null>(null);

function authReducer(state, action) {
  switch (action.type) {
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useAuthApi() {
  const context = React.useContext(AuthContext);
  const router = useRouter();

  if (!context) {
    throw new Error(`useAuthApi must be used within an AuthProvider`);
  }

  const refreshData = () => {
    router.replace(router.asPath);
  };

  const [state, dispatch] = context;

  const onLoadFavoriteDashboard = async () => {
    try {
      dispatch({
        type: TYPE_FETCH,
        payload: { status: "pending" },
      });

      const { response } = await internalGet({
        url: "/api/account/favDashboard",
      }).catch((error) =>
        dispatch({
          type: TYPE_ERROR,
          payload: { error },
        })
      );

      if (response?.ok) {
        dispatch({
          type: TYPE_COMPLETE,
          payload: { status: "completed", error: "" },
        });
      } else {
        const error = { errorMessage: "failed to fetch dashboard" };
        dispatch({
          type: TYPE_ERROR,
          payload: { error },
        });
      }

      return response;
    } catch (error) {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }
  };

  const onTrackLogin = async (payload) => {
    try {
      dispatch({
        type: TYPE_FETCH,
        payload: { status: "pending" },
      });

      const init = {
        url: "/api/account/trackLogin",
        body: payload,
      };

      const { response } = await internalPost(init);
      const data = await response.json();
      const error = { errorMessage: data?.errorMessage };

      if (response?.ok) {
        dispatch({
          type: TYPE_COMPLETE,
          payload: { status: "completed", data: payload },
        });
      } else {
        dispatch({
          type: TYPE_ERROR,
          payload: { error },
        });
      }

      return response;
    } catch (error) {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }
  };

  const onInitGlobalFeedAccess = async () => {
    try {
      dispatch({
        type: TYPE_FETCH,
        payload: { status: "pending" },
      });

      const init = {
        url: "/api/account/initGlobalFeedAccess",
        body: null,
      };

      const { response } = await internalPost(init);
      const data = await response.json();
      const error = { errorMessage: data?.errorMessage };

      if (response?.ok) {
        dispatch({
          type: TYPE_COMPLETE,
          payload: { status: "completed", data: data },
        });
      } else {
        dispatch({
          type: TYPE_ERROR,
          payload: { error },
        });
      }

      return data;
    } catch (error) {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }
  };

  return {
    state,
    dispatch,
    onTrackLogin,
    onLoadFavoriteDashboard,
    onInitGlobalFeedAccess,
  };
}

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(authReducer, {
    fetch: false,
    error: {},
    data: {},
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <AuthContext.Provider value={value} {...props} />;
}

export { AuthProvider, useAuthApi };
