import { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  FormControl,
  Text,
  Select,
  Textarea,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  Stack,
  HStack,
  ButtonGroup,
  IconButton,
  Checkbox,
  Box,
  Switch,
  Flex,
  VStack,
  CheckboxGroup,
  useColorMode,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import { useFlagActionFolders } from "@/services/useFlagActionFolders";
import { AddIcon } from "@chakra-ui/icons";
import { FlagFoldersProvider } from "../admincentre/flag-folders/flag-folders-context";
import FlagFoldersModal from "../admincentre/flag-folders/flag-folders-modal";
import { IFlagFolder } from "@/interfaces/IFlagFolder";
import { IUser } from "@/interfaces/IUser";
import { useUsers } from "@/services/useUsers";
import { useTeams } from "@/services/useTeams";
import { useFlag } from "./flag-context";
import { Select as ReactSelect } from "chakra-react-select";
import CustomDatePickerInput from "@/common/date-range-picker/customDatePickerInput";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { useLanguage } from "../userprofile/language/lang-context";
import moment from "moment";
import { useCurrentUser } from "@/services/useCurrentUser";
import React from "react";
registerLocale("es", es);
import useTheme from "@/common/utility/useTheme";
import SimpleKeywordsInput from "../query-builder/layer-inputs/simple-keywords-input";
import { ScrollableWrapper } from "@/common/div/wrappers";
import UsersSelect from "../query-builder/users-select";
import TeamsSelect from "../query-builder/teams-select";
const fetchPost = async (documentId?: any) => {
  try {
    const response = await fetch(
      `/api/flags/getFlag?id=${documentId}`
    );
    if (response.ok) {
      const post = await response.json();
      return post;
    }
  } catch (error) { }
};
const FlagActionModal = ({
  isNew,
  documentId,
  searchId,
  uniqueId,
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const {
    state: { lang, locale },
  } = useLanguage();

  const {
    state: { fetch },
    onCreateFlag,
    onLoadFlag,
    onUpdateFlag,
    onDeleteFlags
  } = useFlag();
  const { successToast, errorToast } = CustomToast();
  // const { data: existingFlaggedObject, isLoading: isLoadingFlag } = useFlagPost(
  //   { documentId, refreshInterval: 0 }
  // );
  const [postDocumentId, setpostDocumentId] = useState("");
  const { data: folders, isLoading: isLoadingFolders } = useFlagActionFolders();
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: teams, isLoading: isLoadingTeams } = useTeams();
  const { data: currentUser, isLoading: isLoadingCurrentUser } =
    useCurrentUser();
  const [flagFolders, setFlagFolders] = useState<IFolder[]>([]);
  const [actionFolders, setActionFolders] = useState([]);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
  const [actionPriorities, setActionPriorities] = useState<string[]>([]);
  const [selectedActionEmail, setSelectedActionEmail] = useState<ISelectItem>();
  const [showAction, setShowAction] = useState(false);
  const [canDeleteFlag, setCanDeleteFlag] = useState(false);
  const [notes, setNotes] = useState("");
  const [dialogOpen, setDialogOpen] = useState(isOpen);
  const [isBusy, setIsBusy] = useState(false);
  const [externalEmails, setExternalEmails] = useState([]);
  const { colorMode } = useColorMode();
  const [flagFolder, setFlagFolder] = useState<IFolder>({
    id: 0,
    name: "",
  });
  const [flagAction, setFlagAction] = useState<IAction>({
    folder: { id: 0, name: "" },
    assignedMember: { label: "", value: 0 },
    actionPriority: "",
    date: "",
  });

  const isNewflag: boolean = isNew;
  const initialFlagFolder: IFlagFolder = {
    id: 0,
    name: "",
    haveFlags: false,
    createdDate: new Date(),
  };
  type IFolder = {
    id: number;
    name: string;
  };
  type IAction = {
    folder: IFolder;
    assignedMember: ISelectItem;
    actionPriority: string;
    date: string;
  };
  interface ISelectItem {
    label: string;
    value: number;
  }
  // prevent function being recreated on state change
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    onClose();
  }, []);

  useEffect(() => {
    setDefaultLocale(locale);
    // if (!isLoadingUsers) {
    //   const otherUsers = users?.filter(
    //     (user) => currentUser?.userId !== user.id
    //   );
    //   setAllUsers(otherUsers);
    // }
    // if (!isLoadingTeams) {
    //   setAllTeams(teams);
    // }
    if (documentId) {
      setpostDocumentId(documentId);
    }
  }, [
    isLoadingUsers,
    isLoadingTeams,
    users,
    teams,
    locale,
    currentUser,
    documentId,
  ]);

  useEffect(() => {
    (async () => {
    if (!isLoadingFolders) {
      setFlagFolders(folders.flagFolder);
      setActionFolders(folders.actionFolder);
      setActionPriorities(folders.priorities);
    }

      if (!isNewflag) {
        const existingFlaggedObject = await fetchPost(documentId);
        if (existingFlaggedObject) {
        const existingFlag = existingFlaggedObject
          ? existingFlaggedObject
          : undefined;
        if (existingFlag?.flagFolderId > 0 && folders) {
          const folder: IFolder[] = folders?.flagFolder.filter(
            (folder) => folder.id === existingFlag?.flagFolderId
          );

          setFlagFolder((prevState) => {
            return {
              ...prevState,
              id: folder[0].id,
              name: folder[0].name,
            };
          });
          if (existingFlag?.actionFolderId > 0 && folders && users) {
            const folder: IFolder[] = folders?.actionFolder.filter(
              (folder) => folder.id === existingFlag?.actionFolderId
            );
            const assignedEmail: IUser[] = users?.filter(
              (user: IUser) =>
                user.membershipId === existingFlag?.actionAssignedMemberId
            );
            const action: IAction = {
              folder: { id: folder[0].id, name: folder[0].name },
              assignedMember: {
                value: existingFlag.actionAssignedMemberId,
                label: assignedEmail[0].emailAddress,
              },
              actionPriority: existingFlag.actionPriority,
              date: existingFlag.actionDueDate,
            };

            setFlagAction(action);
            setShowAction(true);
          }

          if (existingFlag?.users.length > 0) {
            if (!isLoadingUsers) {
              const existingUsers = existingFlag?.users?.map((eu) => {
                const user = users?.find(u => u.membershipId === eu.membershipId)
                if (user) {
                  const existingUser = { userId: user.id, membershipId: user.membershipId, fullName: user.fullName, sendEmail: eu.sendEmail, sendSms: eu.sendSms, smsNumberIsVerified: user.smsNumberIsVerified ?? false };
                  return existingUser;
                }
              }
              );
              // do not show the creator user in members list as this is default to flag themself when flag is created.
              const existingMembers = existingUsers.filter(u => u.membershipId !== existingFlag.creatorId);
              setSelectedUsers(existingMembers);
            }
          }

          if (existingFlag?.teams.length > 0) {
            if (!isLoadingTeams) {
              const existingTeams = existingFlag?.teams?.map((et) => {
                const team = teams?.find(t => t.id === et.teamId)
                if (team) {
                  const existingTeam = { teamId: team.id, teamName: team.name, sendEmail: et.sendEmail, sendSms: et.sendSms };
                  return existingTeam;
                }
              }
              );
              setSelectedTeams(existingTeams);
            }
          }
          const creator = users?.find(
            (user) => currentUser?.userId === user.id
          );
          if (creator) {
            if (existingFlag.creatorId === creator.membershipId) {
              setCanDeleteFlag(true);
            }
          }
        }
      }
    }
    })();
  }, [isNewflag, isLoadingUsers, isLoadingTeams, isLoadingFolders, flagFolders, folders, currentUser, users, teams, documentId]);
  const handleActionAssign = (selected: ISelectItem) => {
    if (selected) {
      setSelectedActionEmail(selected);
      updateFlagAction("assignedMember", selected);
    }
  };
  const addItem = () => {
    //  setFlagFolder(initialFlagFolder);
    //   onOpen();
    setShowCreateFolderModal(true);
  };
  const flagFolderModalResponse = (data) => {
    //  setRefetch(true);
  };

  const handleDeleteFlag = async () => {
    if (postDocumentId) {
      const response = await onDeleteFlags([postDocumentId]);
      if (response?.ok && !fetch) {
        successToast("Flags", "Successfully deleted a flag");
        handleDialogClose();
      } else {
        errorToast(
          "Flags",
          "Sorry, there is something wrong while deleting a flag. Please contact support."
        );
      }
    }
  };
  const handleSubmitFlag = async () => {
    const members = selectedUsers?.map((user) => {     
      let users = {
        membershipId: user.membershipId,
        sendEmail: user.sendEmail,
        sendSms: user.sendSms
      };

      return users;
    });
    const teams = selectedTeams?.map((team) => {      
      let teams = {
        teamId: team.teamId,
        sendEmail: team.sendEmail,
        sendSms: team.sendSms,
      };

      return teams;
    });
    const currentMembershipId = users?.filter(x => x.id == currentUser.userId)
    const flag = {
      note: notes,
      flagFolderId: flagFolder.id,
      documentId: documentId,
      users: members?.filter(x => x.membershipId != currentMembershipId[0]?.membershipId),
      teams: teams,
      emailList: externalEmails,
      searchId: searchId,
      uniqueId: uniqueId,
      actionFolderId: flagAction.folder.id,
      actionPriority: flagAction.actionPriority,
      actionAssignedMemberId: flagAction.assignedMember.value,
      actionDueDate: flagAction.date,
    };

    setIsBusy(true);
    if (isNew) {
      const response = await onCreateFlag(flag);
      if (response?.status === 200) {
        if (!fetch) {
          successToast("Flags", "Successfully added a flag");
          // modalResponse(flag);
        }
        handleDialogClose();
      } else {
        errorToast(
          "Flags",
          "Sorry, there is something wrong while creating a flag. Please contact support."
        );
      }
    } else {
      const response = await onUpdateFlag(flag);
      if (response?.status === 200) {
        if (!fetch) {
          successToast("Flags", "Successfully updated a flag");
          // modalResponse(flag);
        }
        handleDialogClose();
      } else {
        errorToast(
          "Flags",
          "Sorry, there is something wrong while updating a flag. Please contact support."
        );
      }

      setIsBusy(false);
    }
  };
  const handleFlagFolder = (selectedFolder) => {
    const folder: IFolder[] = folders?.flagFolder.filter(
      (x) => selectedFolder === x.name
    );
    if (folder.length > 0) {
      setFlagFolder((prevState) => {
        return {
          ...prevState,
          id: folder[0].id,
          name: folder[0].name,
        };
      });
    }
  };
  const updateFlagAction = (property: keyof IAction, value: any) => {
    setFlagAction((prevState) => {
      return {
        ...prevState,
        [property]: value,
      };
    });
  };
  const handleNotes = (e) => {
    let inputValue = e.target.value;
    setNotes(inputValue);
  };
  return (
    <>
      <Modal isOpen={dialogOpen} onClose={handleDialogClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {lang?.FLAGPOST_TITLE || "Flag this post for"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={1}>
            <Tabs position="relative" variant="unstyled">
              <TabList>
                {isNewflag && <Tab> {lang?.FLAGPOST_MYSELF || "Myself"}</Tab>}             
                <Tab>{lang?.FLAGPOST_OTHERS || "Others"}</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <Textarea
                placeholder={lang?.FLAGPOST_LEAVEANOTE || "leave a note"}
                // value={notes}
                onBlur={handleNotes}
                onKeyDown={(e) => {
                  e.code === "Space" ? e.stopPropagation() : null;
                }}
              ></Textarea>

              <FormControl mt={1}>
                <HStack spacing={"280px"}>
                  <FormLabel>{lang?.FLAGACTIONS_FLAGFOLDER || "Flag folder"}</FormLabel>
                  <ButtonGroup size="xs" isAttached variant="outline">
                    <Button
                      fontSize="xs"
                      colorScheme="twitter"
                      onClick={addItem}
                    >
                      {lang?.FLAG_FOLDER_CREATE_UI || "Create folder"}
                    </Button>
                    <IconButton
                      aria-label="Add to folders"
                      icon={<AddIcon />}
                    />
                  </ButtonGroup>
                </HStack>
                <Select
                  placeholder={lang?.FLAGACTIONS_SELECTVALUE || "Select value"}
                  value={flagFolder.name}
                  onChange={(e) => handleFlagFolder(e.target.value)}
                >
                  {flagFolders?.map((folder) => (
                    <option key={folder.id} value={folder.name}>
                      {folder.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl mt={2}>
                                <ButtonGroup size='xs' isAttached variant='outline'>
                                    <Button fontSize="xs" colorScheme="twitter" onClick={addItem}>Create folder</Button>
                                    <IconButton aria-label='Add to folders' icon={<AddIcon />} />
                                </ButtonGroup>
                            </FormControl> */}

              <TabPanels>
                {isNewflag && <TabPanel padding={"-1"}></TabPanel>}

                <TabPanel padding={"-1"}>
                  <Flex flexDirection="column" mt={2} backgroundColor="transparent" p={2} borderWidth={1} boxShadow="md" borderRadius="6px">
                    <Text color={theme.modal.fontColor} fontWeight="semibold">{lang?.QUERY_BUILDER_RECIPIENTS || "Recipients"}</Text>
                    <Stack spacing={1} marginTop={3}>
                      {selectedUsers && (<UsersSelect
                        selectedValues={selectedUsers?.map(x => x?.userId.toString())}
                        placeholder={lang?.QUERY_BUILDER_RECIPIENTS_USERS || "Select a user and press enter"}
                        isDisabled={false}
                        onChange={
                          (user) => {
                            const newUser = { userId: user.id, membershipId: user.membershipId, fullName: user.fullName, sendEmail: true, sendSms: false, smsNumberIsVerified: user.smsNumberIsVerified ?? false };
                            setSelectedUsers((prevState) => [
                              ...prevState,
                              newUser
                            ]);

                          }
                        }
                      />)}
                      <ScrollableWrapper>
                        <Box
                          overflowX="hidden"
                          overflowY="auto"
                          maxHeight="200px"
                          id="usersBox"
                        >
                          {selectedUsers?.map(
                            (user, index) => (
                              <Flex
                                key={user.userId.toString()}
                                backgroundColor={
                                  colorMode === "dark" ? "gray.500" : "gray.200"
                                }
                                fontFamily="mono"
                                padding={2}
                                fontSize="xs"
                                mb={1}
                              >
                                <Flex flex="1" minW="0" fontSize="xs" direction={'row'} justifyContent="space-between" width="100%">
                                  {user.fullName}
                                  <CheckboxGroup>
                                    <Stack spacing={2} direction="row" justifyContent="flex-end" >
                                      <Checkbox
                                        size="sm"
                                        fontWeight="normal"
                                        isDisabled={!user.smsNumberIsVerified}
                                        defaultChecked={user.smsNumberIsVerified ? user.sendSms : false}
                                        onChange={(e) => {
                                          user.sendSms = e.target.checked;

                                        }}
                                      >
                                      </Checkbox>
                                      <Text color={theme.modal.fontColor} fontSize="xs">{lang?.QUERY_BUILDER_RECIPIENTS_SENDSMS || "Send SMS"}</Text>
                                      <Checkbox
                                        size="sm"
                                        fontWeight="normal"
                                        defaultChecked={user.sendEmail}
                                        onChange={(e) => {
                                          user.sendEmail = e.target.checked;

                                        }}
                                      >
                                      </Checkbox>
                                      <Text color={theme.modal.fontColor} fontSize="xs">{lang?.QUERY_BUILDER_RECIPIENTS_SENDEMAIL || "Send Email"}</Text>
                                      <IconButton
                                        title={lang?.QUERY_BUILDER_RECIPIENTS_REMOVE_USER || "Remove User"}
                                        variant="ghost"
                                        height="auto"
                                        aria-label="remove user"
                                        size="xs"
                                        onClick={() => {
                                          setSelectedUsers((prevState) =>
                                            prevState.filter((item) => user.userId !== item.userId)
                                          );

                                        }}
                                        icon={
                                          colorMode === "dark" ? (
                                            <i className="fa-sharp fa-light fa-trash-can-xmark"></i>
                                          ) : (
                                            <i className="fa-sharp fa-solid fa-trash-can-xmark"></i>
                                          )
                                        }
                                      />
                                    </Stack>
                                  </CheckboxGroup>
                                </Flex>
                              </Flex>
                            )
                          )}
                        </Box>
                      </ScrollableWrapper>
                    </Stack>
                    <Stack spacing={1} marginTop={1.5}>
                      <TeamsSelect
                        selectedValues={selectedTeams?.map(x => x?.teamId.toString())}
                        placeholder={lang?.QUERY_BUILDER_RECIPIENTS_TEAMS || "Select a team and press enter"}
                        isDisabled={false}
                        onChange={
                          (team) => {
                            setSelectedTeams((prevState) => [
                              ...prevState,
                              {
                                teamId: team.id,
                                teamName: team.name,
                                sendEmail: true,
                              }
                            ]);

                          }
                        }
                      />
                      <ScrollableWrapper>
                        <Box
                          overflowX="hidden"
                          overflowY="auto"
                          maxHeight="200px"
                          id="teamsBox"
                        >
                          {selectedTeams?.map(
                            (team, index) => (
                              <Flex
                                key={team.teamId.toString()}
                                backgroundColor={
                                  colorMode === "dark" ? "gray.500" : "gray.200"
                                }
                                fontFamily="mono"
                                padding={2}
                                fontSize="xs"
                                mb={1}
                              >
                                <Flex flex="1" minW="0" fontSize="xs" direction={'row'} justifyContent="space-between">
                                  {team.teamName}
                                  <CheckboxGroup>
                                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                                      <Checkbox
                                        size="sm"
                                        fontWeight="normal"
                                        isDisabled={false}
                                        defaultChecked={false}
                                        onChange={(e) => {
                                          team.sendSms = e.target.checked;

                                        }}
                                      >
                                      </Checkbox>
                                      <Text color={theme.modal.fontColor} fontSize="xs">{lang?.QUERY_BUILDER_RECIPIENTS_SENDSMS || "Send SMS"}</Text>
                                      <Checkbox
                                        size="sm"
                                        fontWeight="normal"
                                        isChecked={true}
                                        isDisabled={false}
                                      >
                                      </Checkbox>
                                      <Text color={theme.modal.fontColor} fontSize="xs">{lang?.QUERY_BUILDER_RECIPIENTS_SENDEMAIL || "Send Email"}</Text>
                                      <IconButton
                                        title={lang?.QUERY_BUILDER_RECIPIENTS_REMOVE_TEAM || "Remove Team"}
                                        variant="ghost"
                                        height="auto"
                                        aria-label="remove team"
                                        size="xs"
                                        onClick={() => {
                                          setSelectedTeams((prevState) =>
                                            prevState.filter((item) => team.teamId !== item.teamId)
                                          );

                                        }}
                                        icon={
                                          colorMode === "dark" ? (
                                            <i className="fa-sharp fa-light fa-trash-can-xmark"></i>
                                          ) : (
                                            <i className="fa-sharp fa-solid fa-trash-can-xmark"></i>
                                          )
                                        }
                                      />
                                    </Stack>
                                  </CheckboxGroup>
                                </Flex>
                              </Flex>
                            )
                          )}
                        </Box>
                      </ScrollableWrapper>
                    </Stack>
                    <Stack spacing={1} marginTop={1.5} >
                      <SimpleKeywordsInput
                        tabIndex={1}
                        padding={2}
                        showTopics={false}
                        terms={externalEmails}
                        colorScheme="grey"
                        enableEmojiPicker={false}
                        maxLengthPerTag={80}
                        maxTags={25}
                        onChange={(emails) => {
                          setExternalEmails(emails)
                        }}

                        checkForBannedterms={false}
                        placeholder={lang?.QUERYBUILDER_EXTERNALEMAIL || "Add an external email address"}
                        validationRegex='^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$'
                      />
                    </Stack>
                  </Flex>
                </TabPanel>
              </TabPanels>
              <FormControl display="flex">
                <HStack spacing={"370px"} mt={"1"}>
                  <FormLabel mb="0">{lang?.FLAGACTIONS_CREATE_TITLE || "Create action?"}</FormLabel>
                  <Switch
                    id="create-action"
                    isChecked={showAction}
                    onChange={(e) => {
                      setShowAction(e.target.checked ? true : false);
                    }}
                  />
                </HStack>
              </FormControl>
              {showAction ? (
                <VStack>
                  <FormControl mt={1}>
                    <FormLabel>{lang?.FLAGACTIONS_ACTION_STATUS || "Action Status"}</FormLabel>
                    <Select
                      placeholder={lang?.FLAGACTIONS_SELECTVALUE || "Select value"}
                      onChange={(e) =>
                        updateFlagAction("folder", {
                          id: e.target.selectedIndex,
                          name: e.target.value,
                        })
                      }
                      value={flagAction?.folder.name}
                    >
                      {actionFolders?.map((folder) => (
                        <option key={folder.id} value={folder.name}>
                          {folder.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {/* id: number,
                                    assignedMemberId: number,
                                    actionPriority: string,
                                    date: Date */}
                  <FormControl mt={1}>
                    <FormLabel>{lang?.FLAGACTIONS_PRIORITY || "Priority"}</FormLabel>
                    <Select
                      placeholder={lang?.FLAGACTIONS_SELECTVALUE || "Select value"}
                      onChange={(e) =>
                        updateFlagAction("actionPriority", e.target.value)
                      }
                      value={flagAction?.actionPriority}
                    >
                      {actionPriorities?.map((priority: string) => (
                        <option key={priority} value={priority}>
                          {priority}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl mt={1}>
                    <FormLabel>{lang?.FLAGACTIONS_ASSIGN || "Assign"}</FormLabel>
                    <ReactSelect
                      isClearable={true}
                      tagVariant="solid"
                      value={flagAction.assignedMember}
                      onChange={(option) => {
                        if (!option) {
                          setSelectedActionEmail(null);
                        } else {
                          handleActionAssign(option);
                        }
                      }}
                      options={users?.map((user: IUser) => ({
                        value: user.membershipId,
                        label: user.emailAddress,
                      }))}
                      filterOption={(option, searchText) => {
                        return option.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      }}
                    />
                  </FormControl>

                  <FormControl mt={1}>
                    <FormLabel>{lang?.FLAGACTIONS_ACTION_DUE_DATE || "Action Due Date"}</FormLabel>

                    <DatePicker
                      customInput={
                        <CustomDatePickerInput
                          placeholder=""
                          inputLeftAddon="Date: "
                        />
                      }
                      className="{DatePickerWrapper}"
                      isClearable
                      showIcon={true}
                      dateFormat="MMMM d, yyyy"
                      timeIntervals={5}
                      placeholderText={lang?.FLAGACTIONS_SELECTDATE || "Select date"}
                      selected={
                        flagAction.date === "" ? "" : parseISO(flagAction.date)
                      }
                      minDate={moment().toDate()}
                      onChange={function (date: Date): void {
                        updateFlagAction("date", date.toISOString());
                      }}
                    ></DatePicker>
                  </FormControl>
                </VStack>
              ) : (
                <></>
              )}
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={flagFolder?.id > 0 ? false : true}
              colorScheme="messenger"
              mr={3}
              onClick={handleSubmitFlag}
              leftIcon={
                isBusy ? (
                  <i className="fa-sharp fa-solid fa-spinner fa-spin"></i>
                ) : (
                  <i className="fa-sharp fa-solid fa-floppy-disk"></i>
                )
              }
            >
              {lang?.SAVE || "Save"}
            </Button>
            {!isNewflag && canDeleteFlag ? (
              <Button colorScheme="red" mr={3} onClick={handleDeleteFlag}>
                {lang?.FLAG_DELETE_TITLE || "Delete Flag"}
              </Button>
            ) : (
              <></>
            )}
            <Button colorScheme="gray" onClick={handleDialogClose}>{lang?.CANCEL || "Cancel"}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FlagFoldersProvider>
        <FlagFoldersModal
          data={initialFlagFolder}
          isOpen={showCreateFolderModal}
          onClose={() => {
            setShowCreateFolderModal(false);
          }}
          modalResponse={flagFolderModalResponse}
        ></FlagFoldersModal>
      </FlagFoldersProvider>
    </>
  );
};

export default FlagActionModal;
